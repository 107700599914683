import './App.css'
import React, { useEffect, useRef, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Header } from './Header'
import { ButtonColumn } from './ButtonColumn';
import { faPizzaSlice } from '@fortawesome/free-solid-svg-icons';
import { Style } from './MapStyle'

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function MyMapComponent({
  api,
  center,
  zoom,
}) {
  const ref = useRef();

  const [map, setMap] = useState()
  const [places, setPlaces] = useState()
  const [lastWindow, setLastWindow] = useState(new window.google.maps.InfoWindow())

  useEffect(() => {
    (async () => {
      const resp = await api().fetchPlaces()
      setPlaces(resp.data)
    })()

  }, [])

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          mapTypeControl: false,
          streetViewControl: false,
          options: {
            styles: Style
          }
        })
      );
    }
  });

  useEffect(() => {
    if (map) {
      if (places) {
        places.map(place => {

          let color = place.isOneDollar ? "#ff4430" : "#ffdd90"
          let scale = place.isOneDollar ? 0.050 : 0.040

          const marker = new window.google.maps.Marker({
            position: { lat: place.lat, lng: place.lon },
            map,
            title: place.name,
            clickable: true,
            icon: {
              path: faPizzaSlice.icon[4],
              fillColor: color,
              fillOpacity: 1,
              anchor: new window.google.maps.Point(
                faPizzaSlice.icon[0] / 2, // width
                faPizzaSlice.icon[1], // height
              ),
              strokeWeight: 1,
              strokeColor: "#000000",
              scale: scale,
            }
          })

          marker.addListener("click", () => {
            let encodedUri = encodeURIComponent(place.address)
            let google = "https://www.google.com/maps/search/?api=1&query=" + encodedUri
            lastWindow.setContent(`<h2>${place.name}</h2><h3>Lowest price: $${place.price}</h3><br>${place.address}<br><a style="color: blue;" target="_blank" href="${google}">Open in Google Maps</a>`)
  
            lastWindow.close()
            lastWindow.open({
              anchor: marker,
              map,
            });
          });
        }
        );
      }
    }
  }, [places, map])

  return <div ref={ref} id="map" />;
}

export const App = ({ api }) => {
  const center = { lat: 40.7290, lng: -73.968 };
  const zoom = 13;

  return (
    <div>
      <div className='float'>
        <Header > </Header>
      </div>
      <ButtonColumn />

      <div >
        <Wrapper apiKey="AIzaSyD26iQ03Rys8foyArDdB758M6gNopjCxP8" render={render} >
          <div className='mainmap'>
            <MyMapComponent center={center} zoom={zoom} api={api} />
          </div>
        </Wrapper>
      </div>
    </div>
  );
}

export default App