import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'


export default function api() {
    // CHANGEME your heroku instance here....
    const baseUrl = 'https://dollarslice-spring.onrender.com'
    // const baseUrl = 'http://localhost:8080'

    return {
        submitLogin: async (email, password) => {
            return axios.post(`${baseUrl}/auth/login`, {
                email: email,
                password: password
            })
        },
        signup: async (firstName, lastName, email, username, password) => {
            return axios.post(`${baseUrl}/register/email`, {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                username: username,
            })
        },
        getMe: async (authToken) => {
            return axios.get(`${baseUrl}/users/me`, {
                headers: {
                    'x-access-token': authToken
                }
            })
        },
        fetchPlace: async (placeId) => {
            return axios.get(`${baseUrl}/places/${placeId}`)
        },
        fetchPlaces: async () => {
            return axios.get(`${baseUrl}/places/all`)
        }
    }
}