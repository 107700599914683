import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

export const Header = ({ rhs }) => (
    <div className="header d-inline-flex black-link">
        <h2>
        
                <b>💵🍕.org</b>
        </h2>
        {rhs}
    </div>
)