import React from 'react'
import './ButtonColumn.css'

export const ButtonColumn = ({ rhs }) => (
    <div className="list">
        <div className="list-button">
            List
        </div>
        <div className="add-button">
            +
        </div>
    </div>
)